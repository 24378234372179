import { trackMutinyConversion } from '@helpers'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import { Field } from 'formik'
import React, { useState } from 'react'
import SbEditable from 'storyblok-react'

const useStyles = makeStyles(() => ({
  interactiveFormCardSelectRoot: {
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: '1fr 1fr',
  },
}))

const InteractiveFormCardSelectComponent = ({ blok, form }) => {
  const { fieldName, options } = blok
  const classes = useStyles()
  const [currentValue, setCurrentValue] = useState(null)

  const handleFormValue = (value, mutinyConversion) => {
    setCurrentValue(value)

    form.setValues((prev) => ({
      ...prev,
      [fieldName]: value,
      mutinyConversion: mutinyConversion,
    }))

    trackMutinyConversion(mutinyConversion)
  }

  return (
    <SbEditable content={blok}>
      <div className={classes.interactiveFormCardSelectRoot}>
        {!!options &&
          options.map((option) => (
            <div
              key={option._uid}
              onClick={() =>
                handleFormValue(option.value, option.mutinyConversion)
              }
            >
              {renderBlok(option, {
                selectedValue: currentValue,
              })}
            </div>
          ))}
      </div>
    </SbEditable>
  )
}

const InteractiveFormCardSelect = ({ blok }) => {
  return (
    <Field
      blok={blok}
      component={InteractiveFormCardSelectComponent}
      name={blok.fieldName}
      required={true}
    />
  )
}

export default InteractiveFormCardSelect
